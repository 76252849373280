import Head from '@modules/common/components/head';
import Link from 'next/link';
import { ReactElement } from 'react';
import CatalogLayout from '../modules/layout/templates/catalog-layout';
import { NextPageWithLayout } from '../types/global';

const NotFound: NextPageWithLayout = () => {
  return (
    <>
      <Head title="404" description="Something went wrong" />
      <div className="flex min-h-[calc(100vh-64px)] flex-col items-center justify-center">
        <h1 className="text-2xl-semi text-gry-900">Cette page n existe pas</h1>
        <p className="text-small-regular text-gray-700">
          La page que vous recherchez n&apos;existe pas ou a été déplacée.
        </p>
        <Link href="/">
          <span className="text-base-regular mt-4 text-gray-900 underline">
            Retour Accueil
          </span>
        </Link>
      </div>
    </>
  );
};

NotFound.getLayout = (page: ReactElement) => {
  return <CatalogLayout>{page}</CatalogLayout>;
};

export default NotFound;
